/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import CancelModal from '../components/CancelModal';
import * as Yup from 'yup';
import { TextField } from '../components/TextField';
import Button from '../components/Button';
import RadioButton from '../components/RadioButton';
import AccordionLayout from '../components/AccordionLayout';
import Category from '../utils/json/Category.json';
import Relative from '../utils/json/Relative.json';
// import Status from '../utils/json/Status.json';
import { getDoctor } from '../api/doctor';
import { getLocation } from '../api/location';
import Datepicker from '../components/Datepicker';
import Department from '../utils/json/Department.json';
import Select from 'react-select';
import { ageCalculation, stringReplace } from '../utils/common';
import { convertFromFhirList } from '../fhir-evolver';

const Admission = (props) => {
    console.log('propspatientData', props.patientData);
    // const [data, setData] = useState('');
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3',
            color: '#F2F2F2'
        })
    };
    const [isShowing, setIsShowing] = useState(false);
    const navigate = useNavigate();
    const toggle = () => {
        props.isView ? navigate('/patient/admission-list') : setIsShowing(!isShowing);
    };
    const moveBack = () => {
        navigate('/patient/admission-list');
    };

    const patient = props.viewData?.patient ? props.viewData?.patient?.firstName + ' ' + props.viewData?.patient?.surname : '';
    const patients = patient?.toUpperCase();
    const gender = props.viewData?.patient?.gender;
    const patientGender = stringReplace(gender);
    const dob = props.viewData?.patient ? props.viewData?.patient?.birthDate : '';
    const birthDate = dob?.split('T')[0];
    const totalAge = ageCalculation(birthDate);
    const patientUhid = props.viewData?.patient ? props.viewData?.patient?.uhid : '';
    const inPatient = props.viewData?.inPatientId;

    const location = useLocation();
    const patientName = location.state?.patient ? `${location.state?.patient?.firstName} ${location.state?.patient?.surname}` : `${location.state?.firstName} ${location.state?.surname}`;
    const name = patientName.toUpperCase();
    const genderData = location.state?.patient ? `${location.state?.patient?.gender}` : `${location.state?.gender} `;
    const Gender = stringReplace(genderData);
    const age = location.state?.patient ? location.state.patient?.birthDate : location.state?.birthDate;
    const patientAge = ageCalculation(age);
    const uhid = location.state?.patient ? location.state.patient?.uhid : location.state?.uhid;

    const data = props.patientData ? props.patientData?.firstName + ' ' + props.patientData?.surname : '';
    const nameUpper = data?.toUpperCase();
    const patientgender = props.patientData?.gender;
    const gen = stringReplace(patientgender);
    const date = props.patientData ? props.patientData?.birthDate : '';
    const dateofbirth = date?.split('T')[0];
    const ageCal = ageCalculation(dateofbirth);
    const uhidPatient = props.patientData ? props.patientData?.uhid : '';
    // useEffect(() => {
    //     if (props.isView) {
    //         setData({ name: { patients }, age: { totalAge }, gender: { patientGender }, uhid: { patientUhid }, inPatient: { inPatient } });
    //     } else {
    //         setData({ name: { name }, age: { patientAge }, gender: { Gender }, uhid: { uhid }, inPatient: { inPatientId } });
    //     }
    // }, []);

    const [consultantOption, setConsultantOption] = useState([]);
    const [floor, setFloor] = useState('');
    const [ward, setWard] = useState('');
    const [room, setRoom] = useState('');
    const [bed, setBed] = useState('');

    const dropdownJsonFormatHandler = (response) => {
        const intialValue = { label: 'Select', value: '' };
        const list = [];
        if (response.data.entry) {
            for (const item of response.data.entry) {
                list.push({ label: item.resource.name, value: item.resource.id });
            }
            list.unshift(intialValue);
            return list;
        }
    };
    const submitHandler = (data, { setSubmitting }) => {
        formikState.values.uhid = uhid;
        formikState.values.inPatientId = inPatientId;
        props.handleSubmit(data);
        setSubmitting(false);
    };
    const formikState = useFormik({
        initialValues: {},
        // enableReinitialize: true,
        validationSchema: Yup.object({
            department: Yup.string().required('Required *'),
            consultants: Yup.string().required('Required *'),
            admissionDate: Yup.date().required('Required *').nullable(),
            // admissionTime: Yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Required *'),
            category: Yup.string().required('Required *'),
            // procedure: Yup.string().required('Required *'),
            wardType: Yup.string().required('Required *'),
            floorNo: Yup.string().required('Required *'),
            roomNo: Yup.string().required('Required *'),
            bedNo: Yup.string().required('Required *'),
            group: Yup.string().required('Required *')
            // status: Yup.string().required('Required *')

        }),
        onSubmit: submitHandler
    });

    useEffect(() => {
        const intialValue = { label: 'Select', value: '' };
        const allPhysicianlist = [];
        getDoctor().then((response) => convertFromFhirList('PractitionerRole', response.data.entry))
            .then((response) => {
                for (const item of response) {
                    allPhysicianlist.push({ label: `${item.givenName} ${item.family}`, value: item.id });
                }
                allPhysicianlist.unshift(intialValue);
                setConsultantOption(allPhysicianlist);
            });

        getLocation('152').then((response) => {
            const data = dropdownJsonFormatHandler(response);
            setFloor(data);
        });

        if ('viewData' in props) {
            getLocation(props.viewData?.location?.[1].id).then((response) => {
                const data = dropdownJsonFormatHandler(response);
                setWard(data);
            });

            getLocation(props.viewData?.location?.[2].id).then((response) => {
                const data = dropdownJsonFormatHandler(response);
                setRoom(data);
            });
            getLocation(props.viewData?.location?.[3].id).then((response) => {
                const data = dropdownJsonFormatHandler(response);
                setBed(data);
            });
        }

        // Initializing formik values
        formikState.setValues({
            patient: props.viewData?.patient?.id || location.state?.id,
            hospital: props.viewData?.location?.[0].id || '152',
            department: props.viewData?.department || '',
            consultants: props.viewData?.participant?.[0].id || '',
            admissionDate: props.viewData?.admissionDate ? new Date(dates) : currentDate,
            admissionTime: props.viewData?.admissionTime || currentTime,
            category: props.viewData?.category || '',
            procedure: props.viewData?.procedure || '',
            floorNo: props.viewData?.location?.[1].id || '',
            wardType: props.viewData?.location?.[2].id || '',
            roomNo: props.viewData?.location?.[3].id || '',
            bedNo: props.viewData?.location?.[4].id || '',
            group: props.viewData?.Mlc === true ? 'yes' : 'no' || '',
            MlcText: props.viewData?.MlcText || '',
            status: ''

        });
    }, [props]);

    const wardHandler = (e) => {
        const id = e.value;
        formikState.values.floorNo = id;
        formikState.values.wardType = '';
        if (id) {
            getLocation(id).then((response) => {
                const wardData = dropdownJsonFormatHandler(response);
                setWard(wardData);
            });
        }
        setRoom();
        setBed();
    };

    const roomHandler = (e) => {
        const id = e.value;
        formikState.values.wardType = id;
        if (id) {
            getLocation(id).then((response) => {
                const roomData = dropdownJsonFormatHandler(response);
                setRoom(roomData);
            });
        }
        setBed();
    };

    const bedHandler = (e) => {
        const id = e.value;
        formikState.values.roomNo = id;
        if (id) {
            getLocation(id).then((response) => {
                const bedData = dropdownJsonFormatHandler(response);
                setBed(bedData);
            });
        }
    };

    const [activeIndex, setActiveIndex] = useState(0);
    const dates = props.viewData?.admissionDate?.split('T')[0];
    const currentDate = new Date();
    const currentTime = currentDate.getHours() + ':' + currentDate.getMinutes() + ':' + currentDate.getSeconds();
    const inPatientId = currentDate.getFullYear() + '' + (currentDate.getMonth() + 1) + '' + currentDate.getDate() + '' + currentDate.getHours() + '' + currentDate.getMinutes();

    return (
        <form className='w-full flex flex-col gap-y-4 mb-10' onSubmit={formikState.handleSubmit}>

            {props.viewData
                ? <div className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] p-3 flex flex-row items-center justify-between'>
                    <span className='text-white text-base font-roboto '>{patients} | {patientGender} | {totalAge} </span>
                    <span className='text-white text-base font-roboto '> {patientUhid} |  {inPatient} </span>
                </div>
                : props.patientData
                    ? <div className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] p-3 flex flex-row items-center justify-between'>
                        <span className='text-white text-base font-roboto '>{nameUpper} | {gen} | {ageCal} </span>
                        <span className='text-white text-base font-roboto '> {uhidPatient} |  {inPatientId} </span>
                    </div>
                    : <div className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] p-3 flex flex-row items-center justify-between'>
                        <span className='text-white text-base font-roboto '>{name} | {Gender} | {patientAge} </span>
                        <span className='text-white text-base font-roboto '> {uhid} |  {inPatientId} </span>
                    </div>}

            {/* <div className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] p-3 flex flex-row items-center justify-between'>
                <span className='text-white text-base font-roboto '>{data.name} | {data.gender} | {data.age} </span>
                <span className='text-white text-base font-roboto '> {data.uhid} |  {data.inPatient} </span>
            </div> */}

            <div className='flex flex-col flex-wrap w-full'>
                <div className='flex flex-row flex-wrap  gap-y-3 gap-x-[2%]'>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Treating / Admission Department</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="department"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('department', value.value);
                                }}
                                value={
                                    Department
                                        ? Department.find((option) => option.value === formikState.values.department)
                                        : ''}
                                options={Department}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.department && formikState.errors.department
                            ? (
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{formikState.errors.department}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Doctors / Consultants</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="consultants"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('consultants', value.value);
                                }}
                                value={
                                    consultantOption
                                        ? consultantOption.find((option) => option.value === formikState.values.consultants)
                                        : ''}
                                options={consultantOption}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.consultants && formikState.errors.consultants
                            ? (
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{formikState.errors.consultants}</div>
                            )
                            : null}
                    </div>

                    <div className='flex flex-col w-[49%] px-6'>
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto pt-1 text-sm">Date of admission</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Datepicker
                                id='admissionDate'
                                name='admissionDate'
                                dateFormat="dd-MMM-yyyy"
                                // eslint-disable-next-line no-mixed-operators
                                selected={formikState.values.admissionDate && new Date(formikState.values.admissionDate) || null}
                                onChange={newDate => {
                                    formikState.setFieldValue('admissionDate', newDate);
                                }}
                                onBlur={formikState.handleBlur}
                                isDisabled={props.isDisabled}
                            />

                        </div>
                        {formikState.touched.admissionDate && formikState.errors.admissionDate
                            ? (
                                <div className='flex w-full justify-end text-right text-error font-roboto text-sm'>{formikState.errors.admissionDate}</div>
                            )
                            : null}
                    </div>
                    <div className='flex flex-col w-[49%]  px-6'>
                        <TextField
                            label='Time'
                            id='admissionTime'
                            name='admissionTime'
                            type='time'
                            step='1'
                            className='text-col font-roboto bg-gray box-border border-gray focus:outline-none h-10 w-full text-sm px-1'
                            onChange={formikState.handleChange}
                            value={formikState.values.admissionTime}
                            onBlur={formikState.handleBlur}
                            isMandatory={true}
                            disabled={props.isDisabled}
                            style={props.style}
                        />
                        {formikState.touched.admissionTime && formikState.errors.admissionTime
                            ? (
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{formikState.errors.admissionTime}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Category</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="category"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('category', value.value);
                                }}
                                value={
                                    Category
                                        ? Category.find((option) => option.value === formikState.values.category)
                                        : ''}
                                options={Category}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.category && formikState.errors.category
                            ? (
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{formikState.errors.category}</div>
                            )
                            : null}
                    </div>
                    <div className='flex flex-col w-[49%]  px-6'>
                        <TextField
                            label='Operative procedures (If any)'
                            id='procedure'
                            name='procedure'
                            type='text'
                            className='text-col font-roboto bg-gray box-border border-gray focus:outline-none h-10 w-full text-sm px-1'
                            onChange={formikState.handleChange}
                            value={formikState.values.procedure}
                            onBlur={formikState.handleBlur}
                            isMandatory={false}
                            disabled={props.isDisabled}
                            style={props.style}
                        />
                        {/* {formikState.touched.procedure && formikState.errors.procedure
                            ? (
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{formikState.errors.procedure}</div>
                            )
                            : null} */}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Floor No</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="floorNo"
                                isSearchable={true}
                                onChange={(e) => wardHandler(e)}
                                value={
                                    floor
                                        ? floor.find((option) => option.value === formikState.values.floorNo)
                                        : ''}
                                options={floor}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.floorNo && formikState.errors.floorNo
                            ? (
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{formikState.errors.floorNo}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Ward Type</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="wardType"
                                isSearchable={true}
                                onChange={(e) => roomHandler(e)}
                                value={
                                    ward
                                        ? ward.find((option) => option.value === formikState.values.wardType)
                                        : ''}
                                options={ward}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.wardType && formikState.errors.wardType
                            ? (
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{formikState.errors.wardType}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Room No</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="roomNo"
                                isSearchable={true}
                                onChange={(e) => bedHandler(e)}
                                value={
                                    room
                                        ? room.find((option) => option.value === formikState.values.roomNo)
                                        : ''}
                                options={room}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.roomNo && formikState.errors.roomNo
                            ? (
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{formikState.errors.roomNo}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Bed No</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="bedNo"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('bedNo', value.value);
                                }}
                                value={
                                    bed
                                        ? bed.find((option) => option.value === formikState.values.bedNo)
                                        : ''}
                                options={bed}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.bedNo && formikState.errors.bedNo
                            ? (
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{formikState.errors.bedNo}</div>
                            )
                            : null}
                    </div>
                    <div className='flex flex-col w-[49%]  px-6'>
                        <div className='grid grid-cols-c4 gap-[1%]'>
                            <span className='flex flex-row text-black font-roboto text-sm'>MLC:</span>
                            <div className='flex flex-row text-col font-roboto text-sm gap-x-4 flex-wrap'>
                                <div className='flex flex-row text-col font-roboto text-sm gap-x-4'>
                                    <RadioButton
                                        label='Yes'
                                        title='MLC'
                                        name='group'
                                        id='yes'
                                        onChange={formikState.handleChange}
                                        data={formikState.values.group}
                                        value='yes'
                                        onBlur={formikState.handleBlur}
                                        disabled={props.isDisabled}
                                        style={props.style}
                                        checked={formikState.values.group === 'yes'}
                                    />

                                    <RadioButton
                                        label='No'
                                        id='no'
                                        name='group'
                                        onChange={formikState.handleChange}
                                        value='no'
                                        data={formikState.values.group}
                                        onBlur={formikState.handleBlur}
                                        disabled={props.isDisabled}
                                        style={props.style}
                                        checked={formikState.values.group === 'no'}
                                    />

                                </div>
                                {formikState.values.group === 'yes' &&
                                    <input className='text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-8 w-[69%] text-sm px-1' name='MlcText' onChange={formikState.handleChange}
                                        value={formikState.values.MlcText}
                                        onBlur={formikState.handleBlur} disabled={props.isDisabled}
                                        placeholder='Complaint no'
                                        style={props.style} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-center items-center'>
                <AccordionLayout
                    title='Declaration'
                    index={1}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                >
                    <div className='bg-gray ml-6 mr-6 h-[100%] pt-4'>
                        <span className='text-xl font-montserrat font-semibold p-4'>DECLARATION</span>
                        <p className='text-sm text-roboto font-normal text-col p-4'>Above category has been opted by us and expenses involved in the course of treatment during admission have been explained to us.We agree to make all the payments before discharges as per rules of the institute.in case we change to higher category of bed,undertake to pay the charges as per higher category for the entire entire stay.Also we shall be responsible for whatever money or valuables we bring to this institute and keep the same entirely at our on risk.</p>
                        <p className='text-sm text-roboto font-normal text-col p-4'>I/We hereby give consent to Delhi Heart&Lung Institue.For carrying Treatment.... including investigations,medications and operation under any kind of Anaesthesia as my own risk.if presented to me </p>
                        <div className='flex flex-col flex-wrap w-full'>
                            <div className='flex flex-row flex-wrap  gap-y-3 gap-x-[2%]'>
                                <div className='flex flex-col w-[49%] px-6'>
                                    <TextField
                                        label='Name(Kin First)'
                                        id='nameFirst'
                                        name='nameFirst'
                                        type='text'
                                        className='text-col font-roboto bg-white gap-2 box-border border-graycol focus:outline-none h-10 w-full text-sm px-1'
                                        onChange={formikState.handleChange}
                                        value={formikState.values.nameFirst}
                                        onBlur={formikState.handleBlur}
                                        isMandatory={false}
                                        disabled={props.isDisabled}
                                        style={props.style}
                                    />
                                </div>

                                <div className='flex flex-col w-[49%]  px-6'>
                                    <TextField
                                        label='Name(Kin Second)'
                                        id='nameSecond'
                                        name='nameSecond'
                                        type='text'
                                        className='text-col font-roboto bg-white box-border border-graycol focus:outline-none h-10 w-full text-sm px-1'
                                        onChange={formikState.handleChange}
                                        value={formikState.values.nameSecond}
                                        onBlur={formikState.handleBlur}
                                        isMandatory={false}
                                        disabled={props.isDisabled}
                                        style={props.style}
                                    />
                                </div>
                                <div className="flex flex-col w-[49%]  px-6">
                                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                                        <div className="flex flex-row">
                                            <label className="text-black font-roboto text-sm">Relation with patient :</label>
                                        </div>
                                        <Select
                                            name="relation1"
                                            isSearchable={true}
                                            onChange={(value) => {
                                                formikState.setFieldValue('relation1', value.value);
                                            }}
                                            value={
                                                Relative
                                                    ? Relative.find((option) => option.value === formikState.values.relation1)
                                                    : ''}
                                            options={Relative}
                                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                            isDisabled={props.isDisabled}
                                            styles={props.isView ? (props.customStyles) : (colourStyles)}
                                        >
                                        </Select>
                                    </div>
                                </div>
                                <div className="flex flex-col w-[49%]  px-6">
                                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                                        <div className="flex flex-row">
                                            <label className="text-black font-roboto text-sm">Relation with patient :</label>
                                        </div>
                                        <Select
                                            name="relation2"
                                            isSearchable={true}
                                            onChange={(value) => {
                                                formikState.setFieldValue('relation2', value.value);
                                            }}
                                            value={
                                                Relative
                                                    ? Relative.find((option) => option.value === formikState.values.relation2)
                                                    : ''}
                                            options={Relative}
                                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                            isDisabled={props.isDisabled}
                                            styles={props.isView ? (props.customStyles) : (colourStyles)}
                                        >
                                        </Select>
                                    </div>
                                </div>
                                <div className='flex flex-col w-[49%] px-6'>
                                    <TextField
                                        label='Telephone No'
                                        id='telephone1'
                                        name='telephone1'
                                        type='text'
                                        className='text-col font-roboto bg-white box-border border-gray focus:outline-none h-10 w-full text-sm px-1 mr-14'
                                        onChange={formikState.handleChange}
                                        value={formikState.values.telephone1}
                                        onBlur={formikState.handleBlur}
                                        isMandatory={false}
                                        disabled={props.isDisabled}
                                        style={props.style}
                                    />
                                </div>
                                <div className='flex flex-col w-[49%]  px-6'>
                                    <TextField
                                        label='Telephone No'
                                        id='telephone2'
                                        name='telephone2'
                                        type='text'
                                        className='text-col font-roboto bg-white box-border border-gray focus:outline-none h-10 w-full text-sm px-1'
                                        onChange={formikState.handleChange}
                                        value={formikState.values.telephone2}
                                        onBlur={formikState.handleBlur}
                                        isMandatory={false}
                                        disabled={props.isDisabled}
                                        style={props.style}
                                    />
                                </div>

                                <div className='flex flex-col w-[49%]  px-6'>
                                    <TextField
                                        label='Informant'
                                        id='informant'
                                        name='informant'
                                        type='text'
                                        className='text-col font-roboto bg-white box-border border-gray focus:outline-none h-10 w-full text-sm px-1'
                                        onChange={formikState.handleChange}
                                        value={formikState.values.informant}
                                        onBlur={formikState.handleBlur}
                                        isMandatory={false}
                                        disabled={props.isDisabled}
                                        style={props.style}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between p-4 mt-28'>
                            <span className='text-sm font-roboto font-normal'>Sign. of patient or Representative:</span>
                            <span className='text-sm font-roboto font-normal'>Signatory&apos;s of relationship</span>
                            <span className='text-sm font-roboto font-normal'>Sign. of admitting clerk</span>
                        </div>
                    </div>
                </AccordionLayout>
            </div>
            <div className='flex items-center justify-center'>
                <div className='flex flex-row gap-4'>
                    <Button
                        label='Submit'
                        className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[4rem] my-8 text-sm border rounded-md text-white h-12'
                        disabled={props.isDisabled}
                        style={{ display: props.display }}
                    />
                    <button type='button' className="bg-gray border-graycol py-[0.5rem] px-[4rem] my-8 text-sm  border rounded-md text-col h-12"
                        onClick={toggle}>Cancel
                    </button>
                    <CancelModal
                        isShowing={isShowing}
                        hide={toggle}
                        alertMsg='Are you sure you want to close without saving?'
                        back={moveBack}
                    />
                </div>
            </div>
        </form>
    );
};

export default Admission;
