/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { getDoctor } from '../../api/doctor';
import Button from '../../components/Button';
import { getPatientId } from '../../api/patient';
import { ageCalculation, stringReplace } from '../../utils/common';
import { convertFromFhir, convertFromFhirList } from '../../fhir-evolver/index';
import '../../assets/css/Style.css';

const Appointments = () => {
    const [consultantOption, setConsultantOption] = useState([]);
    const [patient, setPatient] = useState('');
    const params = useParams();
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3',
            color: '#F2F2F2'
        })
    };
    useEffect(() => {
        getPatientId(`${params.id}`).then((response) => {
            convertFromFhir('Patient', response.data).then((res) => {
                setPatient(res);
            }).catch(e => {
                return e;
            });
        });
        const intialValue = { label: 'Select', value: '' };
        const allPhysicianlist = [];
        getDoctor().then((response) => convertFromFhirList('PractitionerRole', response.data.entry))
            .then((response) => {
                for (const item of response) {
                    allPhysicianlist.push({ label: `${item.givenName} ${item.family}`, value: item.id });
                }
                allPhysicianlist.unshift(intialValue);
                setConsultantOption(allPhysicianlist);
            });
    }, []);

    const patientName = patient ? patient?.firstName + ' ' + patient?.surname : '';
    const name = patientName.toUpperCase();
    const gender = patient ? patient?.gender : '';
    const patientGender = stringReplace(gender);
    const age = patient ? patient?.birthDate : '';
    const patientAge = ageCalculation(age);
    const patientUhid = patient ? patient?.uhid : '';
    const formikState = useFormik({
        initialValues: {
            date: new Date(),
            consultants: ''
        },
        onSubmit: values => {
            setSelectedDate(values.date);
            console.log(values);
        }
    });

    const [selectedDate, setSelectedDate] = useState(null);
    console.log('selected', selectedDate);
    const [timeslots, setTimeslots] = useState([]);

    const availableTimeslots = [
        {
            date: '2023-02-23',
            timeslots: ['09:00 AM', '10:00 AM', '11:00 AM', '02:00 PM', '03:00 PM']
        },
        {
            date: '2023-02-24',
            timeslots: ['10:00 AM', '11:00 AM', '01:00 PM', '03:00 PM']
        },
        {
            date: '2023-02-25',
            timeslots: ['09:00 AM', '11:00 AM', '02:00 PM', '04:00 PM']
        }
    ];
    // const highlightDates = (date) => {
    //     console.log('date', date);
    //     const dateStr = date.toISOString().slice(0, 10);
    //     console.log('datestr', dateStr);
    //     const dateTimeslots = availableTimeslots.find(
    //         (dateObj) => dateObj.date
    //     );
    //     console.log('datehighlite', dateTimeslots);
    //     if (dateTimeslots.date.includes(dateStr)) {
    //         return { highlighted: true };
    //     }
    //     return {};
    // };

    const handleDateChange = (date) => {
        formikState.setFieldValue('date', date);
        const dateTimeslots = availableTimeslots.find(
            (dateObj) => dateObj.date === date.toISOString().slice(0, 10)
        );
        const allTimeslots = [
            '09:00 AM',
            '10:00 AM',
            '11:00 AM',
            '12:00 PM',
            '01:00 PM',
            '02:00 PM',
            '03:00 PM',
            '04:00 PM',
            '05:00 PM'
        ];
        // console.log('dateobj', dateTimeslots);
        const mergedTimeslots = allTimeslots.map((timeslot) => ({
            time: timeslot,
            isAvailable: dateTimeslots
                ? dateTimeslots.timeslots.includes(timeslot)
                : false
        }));
        setTimeslots(mergedTimeslots);
    };
    return (
        <form className="w-full flex flex-col gap-y-4 mb-10" onSubmit={formikState.handleSubmit}>
            <div className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] p-3 flex flex-row items-center justify-between'>
                <span className='text-white text-base font-roboto '>{name} | {patientGender} | {patientAge}</span>
                <span className='text-white text-base font-roboto '> {patientUhid}</span>
            </div>
            <div className="flex flex-col w-full px-10 gap-y-10">
                <div className='flex flex-row gap-x-10'>
                    <DatePicker
                        id="date"
                        name="date"
                        // eslint-disable-next-line no-mixed-operators
                        selected={formikState.values.date && new Date(formikState.values.date) || null}
                        value={formikState.values.date}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        placeholderText="Select a date"
                        style={{
                            width: '200%',
                            height: '2000px'
                        }}
                        inline
                        // highlightDates={highlightDates}
                        // highlightedClassName="highlighted-date"
                    />
                    <div className='flex flex-col gap-y-10 w-full'>
                        <div className='flex flex-row gap-x-4 w-[60%]'>
                            <span className='text-sm font-roboto'>Doctor/Consultant </span>
                            <Select
                                name="consultants"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('consultants', value.value);
                                }}
                                value={
                                    consultantOption
                                        ? consultantOption.find((option) => option.value === formikState.values.consultants)
                                        : ''}
                                options={consultantOption}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                            >
                            </Select>
                        </div>
                        <div className='flex gap-x-6 flex-wrap w-[60%] gap-y-6'>
                            {timeslots.map((timeslot) => (
                                <span key={timeslot.time} className= {timeslot.isAvailable === true ? 'box-border h-10 border-[#757575] border-[1px] px-4 pt-2 pb-1' : 'box-border border-graycol border-[1px] px-4 pt-2 pb-1'}>{timeslot.time}</span>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center">
                <Button
                    label="Book an Appointment"
                    className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[2rem] my-8 text-base border rounded-md text-white h-12"
                />
            </div>
        </form>
    );
};

export default Appointments;
