/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { Link } from 'react-router-dom';
import Table from '../../components/Table';
import styled from 'styled-components';
import useModal from '../../components/useModal';
import DeleteModal from '../../components/DeleteModal';
import '../../assets/css/Style.css';
import Arrow1 from '../../assets/images/arrow1.svg';
import Search from '../../assets/images/search.svg';
import Edit from '../../assets/images/edit.svg';
import Delete from '../../assets/images/delete.svg';
import View from '../../assets/images/view.svg';
import calendar from '../../assets/images/calendar1.png';
import { patientCustomStyles } from '../../style';
import { getPatientData, deletePatientData } from '../../api/patient';
import Loader from '../../components/Loader';
import { convertFromFhirList } from '../../fhir-evolver/index';
import { FaPlusSquare } from 'react-icons/fa';
import { ageCalculation, stringReplace } from '../../utils/common';
import { config } from '../../utils/config';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const Patient = () => {
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };

    const [deleterow, setDeleterow] = useState('');
    const [openSnackbar] = useSnackbar(options);
    const [spinner, setSpinner] = useState(false);
    const [patientData, setPatientData] = useState([]);
    const [patientFilter, setPatientFilter] = useState([]);
    const [uhid, setUhid] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        setSpinner(true);
        getPatientData(`organization=${config.organizationID}&_count=100`).then((response) => {
            setSpinner(false);
            if (response.data.entry !== undefined) {
                convertFromFhirList('Patient', response.data.entry).then((res) => {
                    const data = res.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                    setPatientData(data);
                    setPatientFilter(data);
                }).catch(e => {
                    return e;
                });
            }
        }).catch(e => {
            return e;
        });
    }, []);
    const deleteData = (id) => {
        const list = [...patientData];
        const selected = list.find((results) =>
            results.id === id
        );
        list.splice(list.indexOf(selected), 1);
        deletePatientData(id)
            .then((response) => {
                setTimeout(() => {
                    setSpinner(true);
                    getPatientData(`organization=${config.organizationID}&_count=100`).then((response) => {
                        console.log('patientres', response);
                        convertFromFhirList('Patient', response.data.entry).then((res) => {
                            const data = res.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                            // console.log('after del api data', data);
                            setPatientData(data);
                            setSpinner(false);
                            openSnackbar('Patient Deleted Successfully');
                            window.location.reload();
                        });
                    }).catch((e) => {
                        return e;
                    });
                }, 1000);
            }).catch((e) => {
                return e;
            });
    };

    const getResult = () => {
        if (uhid.length === 8) {
            uhidFilter(uhid);
        } else if (name.length >= 1) {
            nameFilter(name);
        }
    };

    const uhidFilter = (e) => {
        getPatientData(`organization=${config.organizationID}&_count=100&identifier=${e}`).then((response) => {
            convertFromFhirList('Patient', response.data.entry).then((res) => {
                const data = res.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                setPatientFilter(data);
            });
        }).catch((e) => {
            return e;
        });
    };

    const nameFilter = (e) => {
        getPatientData(`organization=${config.organizationID}&_count=100&name=${e}`).then((response) => {
            convertFromFhirList('Patient', response.data.entry).then((res) => {
                const data = res.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                setPatientFilter(data);
            });
        }).catch((e) => {
            return e;
        });
    };

    const { isShowing, toggle } = useModal();

    const deleteHandler = event => {
        setDeleterow(event);
        toggle();
    };
    const StyledCell = styled.div`
        &.male {
            background: #C8DBDF;
            color: black;
            padding-top:4px;
            padding-bottom:4px;
            padding-left:6px;
            padding-right:6px;
            border-radius: 50%;
            border: 1px solid #D3D3D3;
        }
        &.female {
            background: #DCDBE1;
            color: black;
            padding-top:4px;
            padding-bottom:4px;
            padding-left:9px;
            padding-right:9px;
            border-radius: 50%;
            border: 1px solid #D3D3D3;
        }
        &.other {
            background: #EAE6E3 ;
            color: black;
            padding-top:4px;
            padding-bottom:4px;
            padding-left:7px;
            padding-right:7px;
            border-radius: 50%;
            border: 1px solid #D3D3D3;
        }
    `;
    function getCssClass (value) {
        if (value === 'male') return 'male';
        else if (value === 'female') return 'female';
        return 'other';
    }

    const columns = [
        {
            name: 'UHID',
            selector: (row) => row.uhid,
            sortable: true
        },
        {
            name: 'Name',
            selector: (row) => row.firstName[0].toUpperCase() + ' ' + row.surname.toUpperCase(),
            sortable: true
        },
        {
            name: 'Phone Number',
            selector: (row) => <a className="hover:underline" href="tel:{row.telecom}">{row.telecom}</a>
        },
        {
            name: 'Gender',
            selector: (row) => row.gender,
            sortable: true,
            cell: (row) => (
                <StyledCell className={getCssClass(row.gender)}>
                    {stringReplace(row.gender)}
                </StyledCell>
            )
        },
        {
            name: 'Age',
            selector: (row) => ageCalculation(row.birthDate),
            sortable: true
        },
        {
            name: 'Actions',
            selector: (row, index) => (
                <div className='flex justify-between'>
                    <div>
                        <Link to={`/patient/view/${row.id}`}
                            state={row}>
                            <button className="px-1" id={`view${index}`}>
                                <img className="h-3 w-4" src={View} alt="view" />
                                <Tooltip place="bottom" type="success" anchorId={`view${index}`} effect="solid" content="View"/>
                            </button>
                        </Link>
                        <Link to={`/patient/edit/${row.id}`} state={row}>
                            <button className="px-1" id={`edit${index}`}>
                                <img className="h-3 w-4" src={Edit} alt="edit" />
                                <Tooltip place="bottom" type="success" anchorId={`edit${index}`} effect="solid" content="Edit"/>
                            </button>
                        </Link>
                        <button id={`delete${index}`} onClick={() => deleteHandler(row.id)}>
                            <img className="h-3 w-4" src={Delete} alt="delete" />
                            <Tooltip place="bottom" type="success" anchorId={`delete${index}`} effect="solid" content="Delete"/>
                            <DeleteModal
                                isShowing={isShowing}
                                hide={toggle}
                                deleteData={deleteData}
                                data={deleterow}
                                msg='Are you sure want to delete the patient?'
                            />
                        </button>
                        <Link to={`/patient/admission/${row.id}`} state={row}>
                            <button className="px-1" id={`admission${index}`}>
                                <FaPlusSquare/>
                                <Tooltip place="bottom" type="success" anchorId={`admission${index}`} effect="solid" content="Add Admission"/>
                            </button>
                        </Link>
                        <Link to={`/appointment/${row.id}`} state={row}>
                            <button className="px-1" id={`appointment${index}`}>
                                <img className="h-3 w-4" src={calendar} alt="Appointment"/>
                                <Tooltip place="bottom" type="success" anchorId={`appointment${index}`} effect="solid" content="Appointment"/>
                            </button>
                        </Link>
                    </div>
                </div>
            )
        }
    ];

    return (
        <div>
            <Loader showSpinner={spinner} />
            <div className="bg-background h-full w-full">
                <div className="flex flex-row justify-between px-4">
                    <div className="flex items-center">
                        <Link to={'/home'}>
                            <span className="text-base font-normal pl-3 pr-2 py-2 text-col font-roboto">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-black font-normal text-base  font-roboto px-2 py-2">Patients</span>
                    </div>
                    <div className="flex flex-col p-2">
                        <Link to={'/patient/register'}>
                            <button className="w-64 p-2 h-11 mt-4 bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Add Patient</button>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-row justify-between px-4">
                    <span className="text-3xl font-bold px-3 py-5 text-black">
                        Patients
                    </span>
                    <div className='flex items-center p-2 flex-wrap'>
                        <div className='pt-2 pl-2'>
                            <input className='h-[44px] text-left outline-none px-2'
                                placeholder='UHID'
                                maxLength='8'
                                onChange={(e) => {
                                    setUhid(e.target.value);
                                    setPatientFilter(patientData);
                                }}
                            />
                        </div>
                        <div className='pt-2 pl-2'>
                            <input className='h-[44px] text-left outline-none px-2'
                                placeholder='Patient Name'
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setPatientFilter(patientData);
                                }}
                            />
                        </div>
                        <div className='flex pt-2'>
                            <button type='button' className='px-3 py-3 ml-2 bg-bgrad' onClick={getResult} >
                                <img src={Search} alt='search'/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between !overflow-x-auto">
                    <Table rows={patientFilter} columns={columns} customStyles={patientCustomStyles}
                    />

                </div>
            </div>

        </div>

    );
};

export default Patient;
